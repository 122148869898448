import classes from "./Solutions.module.scss";
import headerImg from "../../assets/icons/headerImg2.jpg";
import SeBackgroundHoverCards from "../../Components/SeBackgroundHoverCards/SeBackgroundHoverCards";
import { ReactComponent as ArrowWithTail } from "../../assets/icons/arrow_with_tail.svg";
import OurStrategy from "./OurStrategy/OurStrategy";
import useIsMobile from "../../Hooks/useIsMobile";
import SectorWiseSolutions from "./SectorWiseSolutionsMobile/SectorWiseSolutions";
import WhoWeHelpMobile from "./WhoWeHelpMobile/WhoWeHelpMobile";
import { sectorWiseSolutionsData } from "../utils/data/sectorWiseSolutions";
import { whoWeHelpContent } from "../utils/data/whoWeHelpContent";
import { useEffect } from "react";

function Solutions() {
  const isMobile = useIsMobile(769);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Set the tab name
    document.title =
      "Net-Zero Solutions | Innovative Platforms for Decarbonization";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Explore our cutting-edge solutions for net-zero transitions. Automate, simplify, and scale your decarbonization efforts with Sustainability Economics."
      );
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Explore our cutting-edge solutions for net-zero transitions. Automate, simplify, and scale your decarbonization efforts with Sustainability Economics.";
      document.head.appendChild(meta);
    }

    // Clean up (if necessary) when component unmounts
    return () => {
      document.title = "Default Title"; // Restore the default title
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          className={classes["header-img"]}
          src={headerImg}
          alt="Contact Us"
        />

        <div className={classes.title}>Solutions</div>
      </div>

      <OurStrategy />

      <SeBackgroundHoverCards
        title="Areas we work in"
        subTitle="We focus on critical areas that drive sustainability, including transition financing, the development of renewable energy projects, creating innovative blockchain-driven marketplaces, and leading the way in developing methodologies for transition credits."
        cards={[
          {
            title: "Transition Finance",
            desc: "There are too many financing instruments and frameworks, which creates confusion and inefficiency.  CLEM streamlines this process by making projects bankable through strategic partnerships with the right stakeholders, including equity and debt partners. CLEM ensures a profitable transition by using the most suitable, low-cost financing instruments.",
          },
          {
            title: "Low Carbon Business Developer",
            desc: "There is a lack of understanding regarding the capacity, land requirements, and costs associated with the right combination of clean energy sources (solar, wind, battery). Additionally, there is insufficient awareness of country-specific policy constraints that impact the feasibility of transitions. CLEM provides standard model driven approach to identify the right combination of renewable energy. It provides customised extensions to the standard models to account for policy driven constraints. By partnering with the right stakeholders, CLEM ensures the development of renewable energy plants at optimal costs.",
          },
          {
            title: "Blockchain driven Marketplace",
            desc: "There is a lack of marketplace for purchasing readily available renewable energy capacity or coal-fired power plant (CFPP) buyouts, hindering the acceleration of transitions from coal to clean energy sources. Our blockchain-driven marketplace provides a platform where readily available renewable energy plants and CFPP buyouts are listed, facilitating faster transitions from CFPPs. This marketplace also enables financial institutions to more effectively decarbonize their portfolios by offering streamlined access to clean energy assets.",
          },
          {
            title: "Transition Credits",
            desc: "There is a lack of clarity around the end-to-end process of transition credits, from their initial concept to cash out, making it difficult for stakeholders to fully understand and capitalize on these financial instruments. As an end-to-end player, methodology developer, and project proponent, we serve as a one-stop shop for managing the entire lifecycle of transition credits. From defining the credits to securing cash flows, we ensure that the process is profitable for all involved parties.",
          },
        ]}
      />
      {isMobile ? (
        <WhoWeHelpMobile />
      ) : (
        <div className={classes["who-we-work-container"]}>
          <div className={classes.title}>Who we help</div>

          <div className={classes.divider} />

          <div className={classes["cards-container"]}>
            {whoWeHelpContent.map((card, index) => (
              <div className={classes.card} key={index}>
                <img
                  src={card.imgSrc}
                  alt={card.title}
                  className={classes.img}
                />
                <div className={classes.title}>{card.title}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isMobile ? (
        <SectorWiseSolutions />
      ) : (
        <div className={classes.solutions}>
          <div className={classes.title}>Sector wise Solutions</div>

          <div className={classes.divider} />

          <div className={classes["sectors-container"]}>
            {sectorWiseSolutionsData.map((solution, index) => (
              <div
                className={classes.sector}
                key={index}
                style={
                  solution.redirectLink
                    ? {
                        cursor: "pointer",
                      }
                    : {}
                }
                onClick={() => window.open(solution.redirectLink, "_blank")}
              >
                <img
                  src={solution.imgSrc}
                  alt={solution.title}
                  className={classes.img}
                />
                <div className={classes.title}>{solution.title}</div>

                {solution.redirectLink ? (
                  <ArrowWithTail className={classes.arrow} />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Solutions;
