import { useState, useRef, useEffect } from "react";
import useAutosizeTextArea from "./useAutoResizeTextArea";
import classes from "./ContactUs.module.scss";
import contactUs from "../../assets/icons/contactUs.jpg";
import Button from "../../Components/Button/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const keyToLabelMapping = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
};

const requiredFields = ["firstName", "lastName", "email"];

function ContactUs() {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [checked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex =
    /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, formValues.message);

  useEffect(() => {
    // Set the tab name
    document.title =
      "Contact Us | Sustainability Economics - Low-Carbon Economy";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Unlock low-carbon economy opportunities with Sustainability Economics. Contact us today, and our team will get back to you promptly."
      );
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Unlock low-carbon economy opportunities with Sustainability Economics. Contact us today, and our team will get back to you promptly.";
      document.head.appendChild(meta);
    }

    // Clean up (if necessary) when component unmounts
    return () => {
      document.title = "Default Title"; // Restore the default title
    };
  }, []);

  const handleChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = evt.target || {};

    setIsSubmitted(false);
    setErrors({});
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const newErrors: Record<string, string> = {};

    if (!emailRegex.test(formValues.email)) {
      newErrors.email = "Please enter a valid email";
    }

    if (formValues.phone && !phoneRegex.test(formValues.phone)) {
      newErrors.phone = "Please enter a valid phone number";
    }

    requiredFields.map((field) => {
      if (!formValues[field as keyof typeof formValues]) {
        newErrors[field] = `${
          keyToLabelMapping[field as keyof typeof keyToLabelMapping]
        } is required`;
      }
    });

    if (!checked) {
      newErrors.privacyPolicy = "Please accept the privacy policy";
    }

    setErrors(newErrors);

    if (Object.values(newErrors).every((val) => !val)) {
      setIsLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const sendData = async () => {
        await fetch(
          "https://6f4k8wcei8.execute-api.ap-south-1.amazonaws.com/prod/api/v1/contactus",
          {
            method: "POST",
            body: JSON.stringify({
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              email: formValues.email,
              phone: formValues.phone,
              subject: formValues.subject,
              message: formValues.message,
            }),
            headers: myHeaders,
          }
        )
          .then((res) => {
            if (res.status === 200) {
              setIsSubmitted(true);
            } else {
              throw new Error("Error while submitting data");
            }
          })
          .catch((err) => {
            toast.error(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

      sendData();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          className={classes["header-img"]}
          src={contactUs}
          alt="Contact Us"
        />

        <div className={classes.title}>Contact Us</div>
      </div>

      <div className={classes["contact-details"]}>
        <div className={classes.title}>Contact Us</div>

        <div className={classes.divider} />

        <p className={`${classes["inner-container"]} ${classes.text}`}>
          Please submit your contact request using the contact form below and
          selecting the nature of your request (Professional investor enquiry,
          Investment proposal, Complaint, or Other). Your personal data will be
          handled with utmost care and in full compliance. You can consult{" "}
          <a
            href="mailto:contact@sustainabilityeconomics.com"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.link}
          >
            Privacy & Data Protection Policy
          </a>{" "}
          for further details.
        </p>

        <div className={classes.divider} />

        <form className={`${classes.form} ${classes["inner-container"]}`}>
          <div className={classes.row}>
            <div className={classes["field-holder"]}>
              <input
                type="text"
                id="First Name"
                placeholder="Enter"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
              />
              <label htmlFor="First Name" className={classes.label}>
                First Name<sup>*</sup>
              </label>

              {errors.firstName && (
                <p className={classes.error}>{errors.firstName}</p>
              )}
            </div>

            <div className={classes["field-holder"]}>
              <input
                type="text"
                id="Last Name"
                placeholder="Enter"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
              />
              <label htmlFor="Last Name" className={classes.label}>
                Last Name<sup>*</sup>
              </label>

              {errors.lastName && (
                <p className={classes.error}>{errors.lastName}</p>
              )}
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes["field-holder"]}>
              <input
                type="email"
                id="Email"
                placeholder="Enter"
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />
              <label htmlFor="Email" className={classes.label}>
                Email<sup>*</sup>
              </label>

              {errors.email && <p className={classes.error}>{errors.email}</p>}
            </div>

            <div className={classes["field-holder"]}>
              <input
                type="tel"
                id="phone"
                placeholder="Enter"
                name="phone"
                value={formValues.phone}
                onChange={handleChange}
              />
              <label htmlFor="phone" className={classes.label}>
                Phone
              </label>

              {errors.phone && <p className={classes.error}>{errors.phone}</p>}
            </div>
          </div>

          <div className={classes["field-holder"]}>
            <input
              type="text"
              id="subject"
              placeholder="Enter"
              name="subject"
              value={formValues.subject}
              onChange={handleChange}
            />
            <label htmlFor="subject" className={classes.label}>
              Subject
            </label>
          </div>

          <div
            className={`${classes["field-holder"]} ${classes["textarea-container"]}`}
          >
            <textarea
              ref={textAreaRef}
              id="message"
              rows={1}
              placeholder="Enter"
              name="message"
              value={formValues.message}
              onChange={handleChange}
            />
            <label htmlFor="message" className={classes.label}>
              Message
            </label>
          </div>

          <div className={classes["btn-container"]}>
            <div className={classes["checkbox-container"]}>
              <input
                type="checkbox"
                id="accept"
                className={classes.checkbox}
                placeholder="Enter"
                checked={checked}
                onChange={() => {
                  setErrors((prev) => ({
                    ...prev,
                    privacyPolicy: "",
                  }));
                  setIsChecked(!checked);
                }}
              />

              <p className={`${classes.text} ${classes.text1}`}>
                I have read the privacy policy. I agree that my details and data
                will be collected and stored electronically in order to answer
                my enquiry.
                <br />
                {errors.privacyPolicy && (
                  <p className={classes.error}>{errors.privacyPolicy}</p>
                )}
              </p>
            </div>

            <Button
              text={
                isLoading
                  ? "Submitting..."
                  : isSubmitted
                  ? "Submitted"
                  : "Submit"
              }
              btnClickHandler={handleSubmit}
              className={`${classes.btn} ${
                isSubmitted ? classes["btn-submitted"] : ""
              } ${isLoading ? classes["btn-disabled"] : ""}`}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
