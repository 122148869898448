import classes from "./OurStrategy.module.scss";
import strategy from "../../../assets/icons/strategy.svg";
import EmptyArrow from "../../../assets/icons/EmptyArrow";
import { useState } from "react";
import useIsMobile from "../../../Hooks/useIsMobile";
import OurStrategyMobile from "../../OurStrategyMobile/OurStrategyMobile";

let strategyArr = [
  {
    title: "Current State Discovery",
    desc: "Data-driven extraction of technical and financial details of the current state of the business.",
  },
  {
    title: "Transition Planning",
    desc: "Practical, location/country-specific transition plans using a phased approach, developed in collaboration with business owners, financial institutions, and policymakers.",
  },
  {
    title: "Economic Gap",
    desc: "Practical, data-driven mechanism to pinpoint the exact financial loss business owners must bear during the transition.",
  },
  {
    title: "Zero/ Low Carbon Transition",
    desc: "Model-driven approaches that consider region/country-specific policies to identify the accurate future state plan towards zero/low carbon state",
  },
  {
    title: "Transition Finance",
    desc: "Data-driven, practical methods to identify transition financing requirements, aligned with clear timelines and various business models. Construction of transition financing frameworks using innovative global financial instruments to make the transition profitable, in collaboration with global equity and debt financial partners.",
  },
  {
    title: "Transition Credits",
    desc: "Playing the role of transition credit methodology developer and project proponent",
  },
  {
    title: "Blockchain-Driven Marketplace",
    desc: "Sourcing the appropriate clean energy technologies by working with clean energy developers to accelerate the transition.",
  },
  {
    title: "Financial Analysis",
    desc: "Providing detailed financial analysis with innovative transition financing instruments and transition credit cash flows for both equity and debt players.",
  },
];

const OurStrategy = () => {
  const isMobile = useIsMobile(769);
  const [dynamicStrategyArr, setDynamicStrategyArr] = useState(strategyArr);
  const [activeBoxNumber, setActiveBoxNumber] = useState(1);
  const [position, setPosition] = useState(0);

  let dotArr = [0, 175, 350, 525, 700, 875, 1050];

  const leftClickHandler = () => {
    setActiveBoxNumber((prevState) => {
      if (prevState - 1 < 1) {
        return strategyArr.length;
      } else {
        return prevState - 1;
      }
    });
    setPosition((prevState) => prevState - 210);
  };
  const rightClickHandler = () => {
    setActiveBoxNumber((prevState) => {
      if (prevState + 1 > strategyArr.length) {
        return 1;
      } else {
        return prevState + 1;
      }
    });

    setPosition((prevState) => prevState + 210);
  };
  if (isMobile){
    return(
      <OurStrategyMobile />
    )
  }
    return (
      <div className={classes["strategy-container"]}>
        <div className={classes["strategy-title"]}>Our Strategy</div>
        <div className={classes["separator"]} />
        <div className={classes["se-strategy-img-container"]}>
          <img
            alt="seStrategy.png"
            src={strategy}
            className={classes["strategy-img"]}
          />
        </div>
        <div className={classes["dots-line-container"]}>
          <div className={classes["line"]}></div>
          <div className={classes["dots-container"]}>
            {dotArr.map((el, index) => (
              <div
                className={
                  index === 3
                    ? `${classes["dot"]} ${classes["dot-expanded"]}`
                    : classes["dot"]
                }
                //   style={{
                //     transform: `translateX(${position}px)`,
                //   }}
              >
                {index === 3 && `0${activeBoxNumber}`}
              </div>
            ))}
          </div>
          <div className={classes["arrow-card-container"]}>
            <div className={classes["arrow-left-container"]}>
              <div onClick={leftClickHandler}>
                <EmptyArrow />
              </div>
            </div>
            <div className={classes["card-container"]}>
              <div className={classes["card-body"]}>
                <p className={classes["card-title"]}>
                  {strategyArr[activeBoxNumber - 1].title}
                </p>
                <p className={classes["card-desc"]}>
                  {strategyArr[activeBoxNumber - 1].desc}
                </p>
              </div>
            </div>
            <div className={classes["arrow-right-container"]}>
              <div onClick={rightClickHandler}>
                <EmptyArrow style={{ transform: "rotate(180deg)" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default OurStrategy;
