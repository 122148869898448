import classes from "./MainEvent.module.scss";
import Button from "../../../../../Components/Button/Button";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as Gpc } from "../../../../../assets/icons/Gujarat.svg";
import { ReactComponent as JacobSolutions } from "../../../../../assets/icons/Jacobson solutions.svg";
import { ReactComponent as Nrel } from "../../../../../assets/icons/NREL.svg";
import { ReactComponent as Selogo } from "../../../../../assets/icons/se_logo_with_text.svg";
import { ReactComponent as Gfanz } from "../../../../../assets/icons/GFANZ.svg";
import { ReactComponent as Smbc } from "../../../../../assets/icons/Smbc.svg";
import { ReactComponent as ClimateBond } from "../../../../../assets/icons/ClimateBondsInitiative.svg";
import { ReactComponent as NewMarket } from "../../../../../assets/icons/newmarketCapital1 .svg";
import { ReactComponent as Aci } from "../../../../../assets/icons/ACI.svg";
import { ReactComponent as Cix } from "../../../../../assets/icons/Climate Impact X.svg";
import { ReactComponent as Idc } from "../../../../../assets/icons/IDX.svg";
import { ReactComponent as Cfa } from "../../../../../assets/icons/Climate finance asia.svg";
import { ReactComponent as Ceb } from "../../../../../assets/icons/clean_energy_bridge.svg";
import { ReactComponent as Anthro } from "../../../../../assets/icons/anthropoceneFixed .svg";
import { ReactComponent as TaraClimate } from "../../../../../assets/icons/taraClimate.svg";
import { ReactComponent as ASix } from "../../../../../assets/icons/articleSix.svg";
import { ReactComponent as Harvard } from "../../../../../assets/icons/harvard.svg";
import { ReactComponent as Kvr } from "../../../../../assets/icons/people/Kvr.svg";
import { ReactComponent as Elizabeth } from "../../../../../assets/icons/people/Elizabeth.svg";
import { ReactComponent as Mahesh } from "../../../../../assets/icons/people/Mahesh.svg";
import { ReactComponent as Jacobson } from "../../../../../assets/icons/people/Jacobson.svg";
import { ReactComponent as Sarah } from "../../../../../assets/icons/people/Sarah.svg";
import { ReactComponent as Green } from "../../../../../assets/icons/people/Green.svg";
import { ReactComponent as Yasui } from "../../../../../assets/icons/people/Yasui.svg";
import { ReactComponent as Laura } from "../../../../../assets/icons/people/Laura.svg";
import { ReactComponent as Habeck } from "../../../../../assets/icons/people/Habeck.svg";
import { ReactComponent as Ana } from "../../../../../assets/icons/people/Ana.svg";
import { ReactComponent as Andrew } from "../../../../../assets/icons/people/Andrew.svg";
import { ReactComponent as Ulf } from "../../../../../assets/icons/people/Ulf.svg";
import { ReactComponent as Denny } from "../../../../../assets/icons/people/Denny.svg";
import { ReactComponent as Jhon } from "../../../../../assets/icons/people/Jhon.svg";
import { ReactComponent as Larsen } from "../../../../../assets/icons/people/Larsen.svg";
import { ReactComponent as Cedric } from "../../../../../assets/icons/people/Cedric.svg";
import { ReactComponent as Chong } from "../../../../../assets/icons/people/Chong.svg";
import { ReactComponent as Garcia } from "../../../../../assets/icons/people/Garcia.svg";
import { ReactComponent as Jamie } from "../../../../../assets/icons/people/jamie.svg";
import { ReactComponent as Sandler } from "../../../../../assets/icons/people/Sandler.svg";
import { ReactComponent as Scott } from "../../../../../assets/icons/people/Scott.svg";
import Growald from "../../../../../assets/images/growald.png";

let linkedInLinkMap = {
  "Arun Mahesh Babu":
    "https://www.linkedin.com/in/arun-mahesh-babu-m-s-ias-97a4b922/",
  "Kasu Venkata Reddy":
    "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
  "Paul Jacobson": "https://www.linkedin.com/in/paul-jacobson-b3716b13/",
  "Sarah Kurtz": "https://www.linkedin.com/in/sarah-kurtz-1167911/",
  "Dolph Habeck": "https://www.linkedin.com/in/dolph-habeck-8bb0464/",
  "Yuki Yasui": "https://www.linkedin.com/in/yuki-yasui-b029a71b/",
  "Dr. Ana Diaz":
    "https://www.linkedin.com/in/dr-eng-ana-diaz-vazquez-1b71321a6/",
  "Andrew Hohns": "https://www.linkedin.com/in/andrew-hohns-6901226/",
  "Cedric Rimaud": "https://www.linkedin.com/in/cedric-rimaud-6695936/",
  "Ulf Erlandsson": "https://www.linkedin.com/in/ulf-erlandsson/",
  "Ignatius Denny Wicaksono":
    "https://www.linkedin.com/in/ignatius-denny-wicaksono-cfa-frm-cipm-3622b236/",
  "John Lo": "https://www.linkedin.com/in/john-lo-a2738225a/",
  "Mikkel Larsen": "https://www.linkedin.com/in/mikkellarsen1/",
  "Chan-yau Chong": "https://www.linkedin.com/in/chan-yau-chong-44b03916/",
  "Andrea Guerrero-Garcia":
    "https://www.linkedin.com/in/andrea-guerrero-09073721/",
  "Elizabeth Willmott":
    "https://www.linkedin.com/in/elizabeth-willmott-26b9734/",
  "Ely Sandler": "https://www.linkedin.com/in/ely-sandler/",
  "Jamie Choi": "https://www.linkedin.com/in/jamie-choi-0058728/",
  "Peter F. Green": "https://www.linkedin.com/in/peter-f-green-9609b7230/",
  "Scott Eaton":
    "https://www.linkedin.com/in/scott-eaton-4993b9a/?originalSubdomain=uk",
} as any;

const registrationUrl =
  "https://events.teams.microsoft.com/event/f938eeb2-36ae-4f29-b665-c1548ee9a8f2@a8606430-fd02-4b66-b04e-e614589bb5ae/registration";

const MainEvent = ({ isMobile }: { isMobile: any }) => {
  const linkClickHandler = (link: string) => () => {
    window.open(link, "_blank");
  };

  const timelineInfo = useCallback(
    (title: string, desc: string, time: string) => {
      return (
        <div className={classes["timeline-info-container"]}>
          <div className={classes["title-time-container"]}>
            <p className={classes["timeline-title"]}>{title}</p>
            <p className={classes["timeline-time"]}>{time}</p>
          </div>
          <p className={classes["timeline-desc"]}>{desc}</p>
        </div>
      );
    },
    []
  );

  const peopleInfo = useCallback((peopleArr: any[]) => {
    return (
      <div className={classes["people-info-contaier"]}>
        <ul className={classes["pi-desc"]}>
          {peopleArr.map((el) => (
            <div className={classes["image-container"]}>
              <div className={classes["image-content"]}>{el.image}</div>
              <div className={classes["type-content"]}>{el.type}</div>
              <span
                className={classes["panelist-name"]}
                onClick={linkClickHandler(linkedInLinkMap[el.name])}
              >
                {el.name}{" "}
              </span>
              <div className={classes["description"]}>{el.designation}</div>
            </div>
          ))}
        </ul>
      </div>
    );
  }, []);
  const blueStrip = useCallback((title: string, time: string) => {
    return (
      <div className={classes["blue-strip-container"]}>
        <p className={classes["bs-title"]}>{title}</p>
        <p className={classes["bs-time"]}>{time}</p>
      </div>
    );
  }, []);

  return (
    <div className={classes["mainevent-container"]}>
      <div className={classes["register-now-container"]}>
        <p className={classes["rnc-title"]}>CLEM (Clean Energy Mechanism)</p>
        <p className={classes["rnc-desc"]}>
          Making Coal Fired Power Plants to Clean Energy Sources Transitions
          Profitable
        </p>
        <p className={classes["rnc-date"]}>
          26<sup>th</sup> Sept’24
        </p>
        <p className={classes["rnc-format"]}>Format: Hybrid</p>
        <p className={classes["rnc-address"]}>
          One World Trade Center 285 Fulton Street, 64th Floor New York, NY
          10007
        </p>
        <div className={classes["register-btn-container"]}>
          <Button
            text="Register now!"
            btnClickHandler={() =>
              window.open(registrationUrl, "_blank", "noopener,noreferrer")
            }
            className={classes["register-btn-styles"]}
          />
        </div>
      </div>
      <div className={classes["agenda-container"]}>
        <p className={classes["agenda-title"]}>Agenda</p>
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo("Registration", "Networking", "8:00 - 8:30 AM")}
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo("Introduction", "Introductory note", "8:30 - 8:40 AM")}
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "CLEM Introduction",
          "CLEM (Clean Energy Mechanism) - Overview By Kasu Venkata Reddy, CEO and Co-founder of Sustainability Economics, outlining CLEM’s vision to transform coal-fired power plants into profitable clean energy sources.",
          "8:40 - 9:00 AM"
        )}
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "Reality check on the readiness of clean energy sources to replace traditional CFPPs as a round-the-clock source",
          "A panel discussion about the readiness of clean energy sources to replace traditional CFPPs as a round-the-clock source.",
          "9:00 - 10:00 AM"
        )}
        <div className={classes["panelist-container"]}>
          <div className={classes["panelist-child-container"]}>
            {peopleInfo([
              {
                name: "Kasu Venkata Reddy",
                designation: "CEO & Co-founder, Sustainability Economics",
                image: !isMobile ? <Kvr /> : <Kvr width={60} height={60} />,
                type: "(Moderator)",
              },
              {
                name: "Arun Mahesh Babu",
                designation:
                  "Managing Director, Gujarat Power Corporation Limited (India)",
                image: !isMobile ? (
                  <Mahesh />
                ) : (
                  <Mahesh width={60} height={60} />
                ),
              },
              {
                name: "Paul Jacobson",
                designation: "President, Clean Energy Bridge",
                image: !isMobile ? (
                  <Jacobson />
                ) : (
                  <Jacobson width={60} height={60} />
                ),
              },
              {
                name: "Sarah Kurtz",
                designation: "Professor, University of California Merced",
                image: !isMobile ? <Sarah /> : <Sarah width={60} height={60} />,
              },
              {
                name: "Peter F. Green",
                designation:
                  "Deputy Laboratory Director & Chief Research Officer, National Renewable Energy Laboratory",
                image: !isMobile ? <Green /> : <Green width={60} height={60} />,
              },
            ])}
          </div>
        </div>
        <div></div>
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "CLEM -Energy Mix demonstration with real time customer case study",
          "Model driven approach to identify right renewable energy mix (solar, wind, battery) combination mainly covering capacity, land, CUF, cost required to replace CFPP based on location (lat-long).",
          "10:00 - 10:20 AM"
        )}
        <div className={classes["agenda-separator"]}></div>
        {blueStrip("Networking Break", "10:20 - 10:40 AM")}
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "Innovative transition financing instruments (equity, debt, concessional funds) to accelerate managed phase out of CFPPs",
          "A panel discussion revolving around innovative transition financing instruments, including equity and debt options, designed to accelerate the managed phase out of CFPPs.",
          "10:40 - 11:40 AM"
        )}
        <div className={classes["panelist-container"]}>
          <div className={classes["panelist-child-container"]}>
            {peopleInfo([
              {
                name: "Kasu Venkata Reddy",
                designation: "CEO & Co-founder, Sustainability Economics",
                image: !isMobile ? <Kvr /> : <Kvr width={60} height={60} />,
                type: "(Moderator)",
              },
              {
                name: "Yuki Yasui",
                designation:
                  "Managing Director, Asia Pacific Network Glasgow Financial Alliance for Net Zero (GFANZ)",
                image: !isMobile ? <Yasui /> : <Yasui width={60} height={60} />,
              },
              {
                name: "Dolph Habeck",
                designation:
                  "Head of Sustainable Solutions, Americas, SMBC Group",
                image: !isMobile ? (
                  <Habeck />
                ) : (
                  <Habeck width={60} height={60} />
                ),
              },
              {
                name: "Dr. Ana Diaz",
                designation:
                  "Global Energy Transition lead, Climate Bonds Initiative",
                image: !isMobile ? <Ana /> : <Ana width={60} height={60} />,
              },
              {
                name: "Andrew Hohns",
                designation: "Chief Executive Officer, Newmarket Capital",
                image: !isMobile ? (
                  <Andrew />
                ) : (
                  <Andrew width={60} height={60} />
                ),
              },
              {
                name: "Ulf Erlandsson",
                designation:
                  "CEO & Founder, Anthropocene Fixed Income Institute (AFII)",
                image: !isMobile ? <Ulf /> : <Ulf width={60} height={60} />,
              },
            ])}
          </div>
        </div>

        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "CLEM - Transition financing demonstration with real time customer case study",
          "Detailed financial analysis to demonstrate CFPP transitions profitable with transition financing instruments.",
          "11:40 - 12:00 PM"
        )}
        <div className={classes["agenda-separator"]}></div>
        {blueStrip("Lunch Break", "12:00- 01:30 PM")}
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "Transition credits, a new financial instrument, to accelerate and enable profitable CFPP transitions",
          "A panel discussion about leveraging transition credits to accelerate CFPP transitions while making it profitable.",
          "01:30 - 02:30 PM"
        )}
        <div className={classes["panelist-container"]}>
          <div className={classes["panelist-child-container"]}>
            {peopleInfo([
              {
                name: "Kasu Venkata Reddy",
                designation: "CEO & Co-founder, Sustainability Economics",
                image: !isMobile ? <Kvr /> : <Kvr width={60} height={60} />,
                type: "(Moderator)",
              },
              {
                name: "John Lo",
                designation: "Founder, Asia Carbon Institute",
                image: !isMobile ? <Jhon /> : <Jhon width={60} height={60} />,
              },
              {
                name: "Mikkel Larsen",
                designation: "Executive Board Member, Climate Impact X",
                image: !isMobile ? (
                  <Larsen />
                ) : (
                  <Larsen width={60} height={60} />
                ),
              },
              {
                name: "Ignatius Denny Wicaksono",
                designation:
                  "Executive Vice President & Business Development, Indonesia Carbon Exchange",
                image: !isMobile ? <Denny /> : <Denny width={60} height={60} />,
              },
              {
                name: "Scott Eaton",
                designation: "Chief Executive Officer, Carbonplace",
                image: !isMobile ? <Scott /> : <Scott width={60} height={60} />,
              },
              {
                name: "Ely Sandler",
                designation:
                  "Managing Partner, Article Six Group and Fellow at the Harvard Kennedy School",
                image: !isMobile ? (
                  <Sandler />
                ) : (
                  <Sandler width={60} height={60} />
                ),
              },
            ])}
          </div>
        </div>
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "CLEM - Financial analysis with transition credits cashflows demonstration, featuring real time customer case study",
          "Detailed financial analysis to demonstrate CFPP transitions profitable with transition credit cashflows combinations.",
          "02:30 - 02:50 PM"
        )}
        <div className={classes["agenda-separator"]}></div>
        {blueStrip("Networking Break", "02:50 - 03:10 PM")}
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "CLEM - End to End demonstration with real time customer case study",
          "CLEM's end-to-end real-time case study showcasing a profitable transition from coal-fired power plants to clean energy sources.",
          "03:10 - 03:40 PM"
        )}

        <div className={classes["agenda-separator"]}></div>
        {blueStrip("Networking Break", "03:40 - 04:00 PM")}
        <div className={classes["agenda-separator"]}></div>
        {timelineInfo(
          "Financing Just Transition",
          "A panel discussion around financing just transitions.",
          "04:00 - 04:45 PM"
        )}
        <div className={classes["panelist-container"]}>
          {peopleInfo([
            {
              name: "Kasu Venkata Reddy",
              designation: "CEO & Co-founder, Sustainability Economics",
              image: !isMobile ? <Kvr /> : <Kvr width={60} height={60} />,
              type: "(Moderator)",
            },
            {
              name: "Cedric Rimaud",
              designation:
                "Deputy General Manager - Sustainability Solutions, SMBC Group",
              image: !isMobile ? <Cedric /> : <Cedric width={60} height={60} />,
            },
            {
              name: "Chan-yau Chong",
              designation:
                "Member Board of Directors (Climate Finance Asia), Chief Executive Officer at CarbonCare InnoLab",
              image: !isMobile ? <Chong /> : <Chong width={60} height={60} />,
            },
            {
              name: "Andrea Guerrero-Garcia",
              designation: "Director of Field Innovation, Growald Climate Fund",
              image: !isMobile ? <Garcia /> : <Garcia width={60} height={60} />,
            },
            {
              name: "Jamie Choi",
              designation: "Chief Executive Officer, Tara Climate Foundation",
              image: !isMobile ? <Jamie /> : <Jamie width={60} height={60} />,
            },
          ])}
        </div>
        <div className={classes["agenda-separator"]}></div>
        {blueStrip("Networking Break & Refreshments", "04:45 - 05:30 PM")}
        <div className={classes["agenda-separator"]}></div>
        <div className={classes["insights-container"]}>
          <div className={classes["insights-header"]}>
            <p className={classes["insights-title"]}>
              You'll get expert insights on
            </p>
            <Button
              text="Register Now!"
              btnClickHandler={() =>
                window.open(registrationUrl, "_blank", "noopener,noreferrer")
              }
              className={classes["insights-button"]}
            />
          </div>
          <ul className={classes["insights-content"]}>
            <li>
              CLEM's practical approach that leverages innovative transition
              finance instruments to accelerate the phase out of CFPPs and make
              it profitable.
            </li>
            <li>
              Model-driven approach for identifying the right clean energy
              sources to replace traditional CFPP.
            </li>
            <li>
              Detailed financial analysis, including a real-time customer
              demonstration, to illustrate the profitability of CFPP transitions
              using transition financing instruments.
            </li>
            <li>
              Just transition and possibilities of unlocking investments as part
              of it.
            </li>
            <li>
              Transition credits, as a new financial instrument to accelerate
              CFPP transitions.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainEvent;
