import React, { useEffect, useState } from "react";
import classes from "./TabbedComponent.module.scss";

interface TabbedComponentProps {
  tabs: string[]; // Array of tab labels
  defaultActiveTab?: string;
  onChange: (activeTab: string) => void; // Callback function to notify parent of active tab change
  tabStyle?: React.CSSProperties;
  tabItemStyle?: React.CSSProperties;
}

const TabbedComponent: React.FC<TabbedComponentProps> = ({
  tabs,
  defaultActiveTab,
  onChange,
  tabStyle = {},
  tabItemStyle = {},
}) => {
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    setActiveTab(defaultActiveTab || tabs[0]);
  }, [tabs]);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    onChange?.(tab);
  };

  return (
    <ul className={classes["tab-container"]} style={tabStyle}>
      {tabs.map((tab, index) => (
        <li
          key={index}
          onClick={() => handleTabClick(tab)}
          className={`${classes["tab-item"]} ${
            activeTab === tab ? classes["active"] : ""
          }`}
          style={tabItemStyle}
        >
          {tab}
        </li>
      ))}
    </ul>
  );
};

export default TabbedComponent;
