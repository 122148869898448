import classes from "./OurAssociation.module.scss";
import styles from "../../../../DesignSystem/_classes.module.scss";
import { useEffect, useRef, useState } from "react";
import Pcaf from "../../../../assets/icons/PCAF.svg";
import Ggp from "../../../../assets/icons/GGP.svg";
import Ifrs from "../../../../assets/icons/IFRS.svg";
import Tcfd from "../../../../assets/icons/TCFD.svg";
import Usec from "../../../../assets/icons/US_SEC.svg";
import Efrag from "../../../../assets/icons/EFRAG.svg";
import Cdp from "../../../../assets/icons/CDP.svg";
import Sasb from "../../../../assets/icons/SASB.svg";
import Sbt from "../../../../assets/icons/SBT.svg";
import Gfanz from "../../../../assets/icons/GFANZ.svg";
import Icma from "../../../../assets/icons/ICMA.svg";
import ClimateBonds from "../../../../assets/icons/ClimateBondsInitiative.svg";
import Asean from "../../../../assets/icons/ASEAN.svg";
import Vcmi from "../../../../assets/icons/VCMI.svg";
import Car from "../../../../assets/icons/CAR.svg";
import Gs from "../../../../assets/icons/GS.svg";
import Acg from "../../../../assets/icons/ACG.svg";
import Vcs from "../../../../assets/icons/VCS.svg";
import Iea from "../../../../assets/icons/IEA.svg";
import Irena from "../../../../assets/icons/IRENA.svg";
import Ipcc from "../../../../assets/icons/IPCC.svg";
import Ngfs from "../../../../assets/icons/NGFS.svg";

const oacArr = [
  {
    title: "Accounting",
    svg: [Ggp, Pcaf],
  },
  {
    title: "Reporting",
    svg: [Ifrs, Tcfd, Usec, Efrag, Cdp, Sasb],
  },
  {
    title: "Target Setting",
    svg: [Sbt],
  },
  {
    title: "Transition Finance",
    svg: [Gfanz],
  },
  {
    title: "Green Bonds",
    svg: [Icma, ClimateBonds, Asean],
  },
  {
    title: "Carbon Markets",
    svg: [Vcmi, Car, Gs, Acg, Vcs],
  },
  {
    title: "Energy",
    svg: [Iea, Irena],
  },
  {
    title: "Scenario Analysis",
    svg: [Iea, Ipcc, Ngfs],
  },
];

const OurAssociationMobile = () => {
  const associationRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    let counter = activeIndex;
    const interval = setInterval(() => {
      if (counter === 3) {
        associationRef.current?.scrollTo({
          left: associationRef.current.scrollWidth,
          behavior: "smooth",
        });
      }

      if (counter === oacArr.length - 1) {
        counter = 0;
        associationRef.current?.scrollTo({
          left: 0,
          behavior: "smooth",
        });
        setActiveIndex(0);
      } else {
        counter++;
        setActiveIndex(counter);
      }
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes["our-association-container"]}>
      <div className={classes["oac-title"]}>Our associations</div>
      <div className={classes["oac-separator"]}></div>

      <div
        ref={associationRef}
        className={`${classes["oac-elements-container"]} ${styles["hide-scrollbar"]}`}
      >
        {oacArr.map(({ title }, index: number) => (
          <div
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`${classes["oac-element-title"]} ${
              activeIndex === index ? classes["oac-element-title-active"] : ""
            }`}
          >
            {title}
          </div>
        ))}
      </div>

      <div className={classes["oac-svg-container"]}>
        {oacArr[activeIndex].svg.map((svg, index: number) => (
          <img key={index} src={svg} />
        ))}
      </div>
    </div>
  );
};

export default OurAssociationMobile;
