import classes from "./Footer.module.scss";
import { ReactComponent as Instagram } from "../../../assets/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as Youtube } from "../../../assets/icons/youtube.svg";
import SeLogoWhite from "../../../assets/icons/SeLogoWhite";
import Button from "../../../Components/Button/Button";
import useIsMobile from "../../../Hooks/useIsMobile";
import { useNavigate } from "react-router-dom";

const solutionsArr = [
  {
    text: "CLEM",
    link: process.env.REACT_APP_CLEM_URL,
  },
];

const platformsArr = [
  "Climate Scenario Analysis",
  "Transition Finance",
  "Carbon Credits",
  "Marketplace",
  "Carbon Markets",
  "Data Providers",
];

const companyArr = [
  { text: "About Us", link: "/about-us" },
  { text: "Contact Us", link: "/contact-us" },
  { text: "Careers", link: "/about-us" },
];

const clemClickHandler = () => {
  window.open(process.env.REACT_APP_CLEM_URL, "_blank");
};

const Footer = () => {
  const isMobile = useIsMobile(980);

  const navigate = useNavigate();

  const footerLinkNavigateHandler = (link: string, redirect: boolean) => () => {
    if (redirect) {
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  };

  return (
    <div className={classes["footer-container"]}>
      {isMobile ? (
        <>
          <div className={classes["clem-email-sm-container"]}>
            <div className={classes["clem-logo"]}>
              <SeLogoWhite width={100} height={49} />
            </div>
            <div className={classes["clem-desc"]}>
              Subscribe to our blogs and newsletters now!
            </div>
            <div className={classes["email-input-container"]}>
              <input placeholder="Enter your email address here"></input>
              <Button text="Sign up" btnClickHandler={() => {}} />
            </div>
          </div>
          <div className={classes["spcr-container-mobile"]}>
            <div className={classes["solutions-platform-container-mobile"]}>
              <div className={classes["solutions-container-mobile"]}>
                <p className={classes["heading-mobile"]}>Solutions</p>
                <div className={classes["child-container-mobile"]}>
                  {solutionsArr.map((el, index) => (
                    <p
                      key={index}
                      onClick={() => window.open(el.link, "_blank")}
                    >
                      {el.text}
                    </p>
                  ))}
                </div>
              </div>
              <div className={classes["platforms-container-mobile"]}>
                <p className={classes["heading-mobile"]}>Platforms</p>
                <div
                  className={`${classes["child-container-mobile"]} ${classes["child-container-mobile-platform"]}`}
                >
                  {platformsArr.map((el) => (
                    <p>{el}</p>
                  ))}
                </div>
              </div>
            </div>

            <div className={classes["cr-wrapper"]}>
              <div className={classes["company-container-mobile"]}>
                <p className={classes["heading-mobile"]}>Company</p>
                <div className={classes["child-container-mobile"]}>
                  {companyArr.map((el) => (
                    <p onClick={footerLinkNavigateHandler(el.link, false)}>
                      {el.text}
                    </p>
                  ))}
                </div>
              </div>
              <div className={classes["resources-container-mobile"]}>
                <p className={classes["heading-mobile"]}>Resources</p>
                <div className={classes["child-container-mobile"]}>
                  <p onClick={() => navigate("/events-overview")}>Events</p>
                  <p
                    onClick={() =>
                      window.open(
                        "https://sustainabilityeconomicsnews.com/",
                        "_blank"
                      )
                    }
                  >
                    Media
                  </p>
                </div>
              </div>

              <div className={classes["tps-container-mobile"]}>
                <p className={classes["heading-mobile"]}>Legal</p>
                <div className={classes["child-container-mobile"]}>
                  <p className={classes["tps-disabled"]}>Terms</p>
                  <p className={classes["tps-disabled"]}>Privacy</p>
                  <p className={classes["tps-disabled"]}>Security</p>
                </div>
              </div>
            </div>

            <div className={classes["address-container-mobile"]}>
              <div className={classes["inner-container"]}>
                <div className={classes["title"]}>SINGAPORE</div>
                <div className={classes["address-details"]}>
                  328 North Bridge Road, Raffles Hotel Arcade,{" "}
                  <span>Level 02-20 188719</span>
                </div>
              </div>

              <div className={classes["inner-container"]}>
                <div className={classes["title"]}>UNITED STATES</div>
                <div className={classes["address-details"]}>
                  One World Trade Center, Suite 8500, <span>NY 10048</span>
                </div>
              </div>

              <div className={classes["inner-container"]}>
                <div className={classes["title"]}>INDIA</div>
                <div className={classes["address-details"]}>
                  Survey No. 133, Qube Software Park, 2, Bhoganahalli Rd, Hobli,
                  East, Varthur, Bengaluru, Karnataka 560103
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes["clem-email-sm-container"]}>
            <div className={classes["clem-logo"]}>
              <SeLogoWhite />
            </div>
            <div className={classes["clem-desc"]}>
              Subscribe to our blogs and newsletters now!
            </div>
            <div className={classes["email-input-container"]}>
              <input placeholder="Enter your email address here"></input>
              <Button text="Sign up" btnClickHandler={() => {}} />
            </div>
            <div className={classes["social-media-links-container"]}>
              <div
                onClick={footerLinkNavigateHandler(
                  "https://www.linkedin.com/company/sustainabilityeconomics/mycompany/",
                  true
                )}
              >
                <LinkedIn />
              </div>
              <div
                onClick={footerLinkNavigateHandler(
                  "https://www.instagram.com/sustainabilityeconomics/",
                  true
                )}
              >
                <Instagram />
              </div>
              <div
                onClick={footerLinkNavigateHandler(
                  "https://www.youtube.com/@sustainabilityeconomics/",
                  true
                )}
              >
                <Youtube />
              </div>
            </div>
            <div className={classes["copyrights"]}>
              © {new Date().getFullYear()} Sustainability Economics
            </div>
          </div>

          <div className={classes["right-container"]}>
            <div className={classes["spcr-container"]}>
              <div className={classes["solutions-container"]}>
                <p className={classes["heading"]}>Solutions</p>
                <div className={classes["child-container"]}>
                  {solutionsArr.map((el, index) => (
                    <p
                      key={index}
                      onClick={() => window.open(el.link, "_blank")}
                    >
                      {el.text}
                    </p>
                  ))}
                </div>
              </div>
              <div className={classes["platforms-container"]}>
                <p className={classes["heading"]}>Platforms</p>
                <div className={classes["child-container"]}>
                  {platformsArr.map((el) => (
                    <p>{el}</p>
                  ))}
                </div>
              </div>
              <div className={classes["company-container"]}>
                <p className={classes["heading"]}>Company</p>
                <div className={classes["child-container"]}>
                  {companyArr.map((el) => (
                    <p onClick={footerLinkNavigateHandler(el.link, false)}>
                      {el.text}
                    </p>
                  ))}
                </div>
              </div>
              <div className={classes["resources-container"]}>
                <p className={classes["heading"]}>Resources</p>
                <div className={classes["child-container"]}>
                  <p onClick={() => navigate("/events-overview")}>Events</p>
                  <p
                    onClick={() =>
                      window.open(
                        "https://sustainabilityeconomicsnews.com/",
                        "_blank"
                      )
                    }
                  >
                    Media
                  </p>
                </div>
              </div>
            </div>

            <div className={classes["address-container"]}>
              <div className={classes["inner-container"]}>
                <div className={classes["title"]}>SINGAPORE</div>
                <div className={classes["address-details"]}>
                  328 North Bridge Road, Raffles Hotel Arcade,{" "}
                  <span>Level 02-20 188719</span>
                </div>
              </div>

              <div className={classes["inner-container"]}>
                <div className={classes["title"]}>UNITED STATES</div>
                <div className={classes["address-details"]}>
                  One World Trade Center, Suite 8500, <span>NY 10048</span>
                </div>
              </div>

              <div className={classes["inner-container"]}>
                <div className={classes["title"]}>INDIA</div>
                <div className={classes["address-details"]}>
                  Survey No. 133, Qube Software Park, 2, Bhoganahalli Rd, Hobli,
                  East, Varthur, Bengaluru, Karnataka 560103
                </div>
              </div>
            </div>

            <div className={classes["tps-container"]}>
              <p className={classes["tps-disabled"]}>Terms</p>
              <p className={classes["tps-disabled"]}>Privacy</p>
              <p className={classes["tps-disabled"]}>Security</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
