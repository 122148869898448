export interface SectorWiseSolution {
  imgSrc: string;
  title: string;
  redirectLink?: string;
}

export const sectorWiseSolutionsData: SectorWiseSolution[] = [
  {
    imgSrc:
      "https://se-website-image.s3.ap-south-1.amazonaws.com/apartment-office-tall-building-construction-top-view-tower-crane-city-landscape-stretching-horizon-drone-aerial-photography.jpg",
    title: "Real Estate",
  },
  {
    imgSrc:
      "https://se-website-image.s3.ap-south-1.amazonaws.com/power-plant-with-smoke-coming-out-it-power-plant-background.jpg",
    title: "Energy",
    redirectLink: process.env.REACT_APP_CLEM_URL,
  },
  {
    imgSrc:
      "https://se-website-image.s3.ap-south-1.amazonaws.com/environmental-pollution-factory-exterior.jpg",
    title: "Heavy Industries",
  },
];
