const EmptyArrow = ({ style = {} }) => {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.66602 16L0.666016 8L8.66602 0L10.5327 1.86667L4.39935 8L10.5327 14.1333L8.66602 16Z"
        fill="#111111"
      />
    </svg>
  );
};

export default EmptyArrow;
