import classes from "./Banner.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_icon.svg";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useIsMobile from "../../../Hooks/useIsMobile";

const registrationUrl =
  "https://events.teams.microsoft.com/event/f938eeb2-36ae-4f29-b665-c1548ee9a8f2@a8606430-fd02-4b66-b04e-e614589bb5ae/registration";

const Banner = ({ setBannerVisibility }: any) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile(600);

  const eventsClickHandler = () => {
    navigate("/events-overview");
  };

  const crossClickHandler = () => {
    setBannerVisibility?.(false);
  };

  const registerClickHandler = () => {
    crossClickHandler();
    window.open(registrationUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={classes["banner-container"]}>
      <div className={classes["left-content"]}>
        {/* <div
          className={classes["close-icon-container"]}
          onClick={crossClickHandler}
        >
          <CloseIcon />
        </div> */}
        <div className={classes["banner-text-container"]}>
          Join Sustainability Economics at <span>Climate Week NYC 2024</span>.
          {isMobile ? (
            <></>
          ) : (
            <>
              {" "}
              Check out our <a onClick={eventsClickHandler}>Events</a> page to
              learn more.
            </>
          )}
        </div>
      </div>

      <div className={classes["register-btn-container"]}>
        {isMobile ? (
          <div
            className={classes["register-now-text"]}
            onClick={registerClickHandler}
          >
            Register Now
          </div>
        ) : (
          <Button
            text="Register Now!"
            btnClickHandler={registerClickHandler}
            btnStyles={{
              backgroundColor: "#288f90",
              color: "white",
              fontWeight: 400,
              letterSpacing: "0.5px",
              height: "100%",
              width: "100%",
            }}
            className={classes["register-container"]}
          />
        )}
      </div>
    </div>
  );
};

export default Banner;
