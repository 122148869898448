import classes from "./OurAssociation.module.scss";
import Pcaf from "../../../assets/icons/PCAF.svg";
import Ggp from "../../../assets/icons/GGP.svg";
import Ifrs from "../../../assets/icons/IFRS.svg";
import Tcfd from "../../../assets/icons/TCFD.svg";
import Usec from "../../../assets/icons/US_SEC.svg";
import Efrag from "../../../assets/icons/EFRAG.svg";
import Cdp from "../../../assets/icons/CDP.svg";
import Sasb from "../../../assets/icons/SASB.svg";
import Sbt from "../../../assets/icons/SBT.svg";
import Gfanz from "../../../assets/icons/GFANZ.svg";
import Icma from "../../../assets/icons/ICMA.svg";
import ClimateBonds from "../../../assets/icons/ClimateBondsInitiative.svg";
import Asean from "../../../assets/icons/ASEAN.svg";
import Vcmi from "../../../assets/icons/VCMI.svg";
import Car from "../../../assets/icons/CAR.svg";
import Gs from "../../../assets/icons/GS.svg";
import Acg from "../../../assets/icons/ACG.svg";
import Vcs from "../../../assets/icons/VCS.svg";
import Iea from "../../../assets/icons/IEA.svg";
import Irena from "../../../assets/icons/IRENA.svg";
import Ipcc from "../../../assets/icons/IPCC.svg";
import Ngfs from "../../../assets/icons/NGFS.svg";
import { useState } from "react";

let oacArr = [
  {
    title: "Accounting",
    svg: [Ggp, Pcaf],
  },
  {
    title: "Reporting",
    svg: [Ifrs, Tcfd, Usec, Efrag, Cdp, Sasb],
  },
  {
    title: "Target Setting",
    svg: [Sbt],
  },
  {
    title: "Transition Finance",
    svg: [Gfanz],
  },
  {
    title: "Green Bonds",
    svg: [Icma, ClimateBonds, Asean],
  },
  {
    title: "Carbon Markets",
    svg: [Vcmi, Car, Gs, Acg, Vcs],
  },
  {
    title: "Energy",
    svg: [Iea, Irena],
  },
  {
    title: "Scenario Analysis",
    svg: [Iea, Ipcc, Ngfs],
  },
];

const OurAssociation = () => {
  const [hoveredIndex, setHoveredIndex] = useState(0);

  const mouseEnterHandler = (receivedIndex: number) => () => {
    setHoveredIndex(receivedIndex);
  };

  return (
    <div className={classes["our-association-container"]}>
      <div className={classes["oac-title"]}>Our associations</div>
      <div className={classes["oac-separator"]}></div>
      <div className={classes["oac-elements-container"]}>
        {oacArr.map((el, index: number) => (
          <>
            <div
              className={
                hoveredIndex === index
                  ? `${classes["oac-element-representor"]} ${classes["oac-element-representor-expanded"]}`
                  : classes["oac-element-representor"]
              }
              onMouseEnter={mouseEnterHandler(index)}
            >
              <div className={classes["ele-title"]}>{el.title}</div>
              {hoveredIndex === index && (
                <div className={classes["ele-img-container"]}>
                  {el.svg.map((svgEl) => (
                    <img src={svgEl}></img>
                  ))}
                </div>
              )}
            </div>
            <div className={classes["oac-elements-separator"]}></div>
          </>
        ))}
      </div>
    </div>
  );
};

export default OurAssociation;
