import classes from "./Clem.module.scss";
import { ReactComponent as TopWave } from "../../../../assets/icons/top_wave.svg";
import { ReactComponent as DownWave } from "../../../../assets/icons/down_wave.svg";
import { ReactComponent as Gfanz } from "../../../../assets/icons/GFANZ.svg";
import { ReactComponent as Smbc } from "../../../../assets/icons/Smbc.svg";
import { ReactComponent as Aci } from "../../../../assets/icons/ACI.svg";
import { ReactComponent as Gpc } from "../../../../assets/icons/Gujarat.svg";
import { ReactComponent as Cix } from "../../../../assets/icons/Climate Impact X.svg";
import { ReactComponent as Idc } from "../../../../assets/icons/IDX.svg";
import { ReactComponent as JacobSolutions } from "../../../../assets/icons/Jacobson solutions.svg";
import { ReactComponent as Cfa } from "../../../../assets/icons/Climate finance asia.svg";
import { ReactComponent as Nrel } from "../../../../assets/icons/NREL.svg";
import { ReactComponent as ClimateBonds } from "../../../../assets/icons/ClimateBondsInitiative.svg";
import { ReactComponent as NewMarket } from "../../../../assets/icons/newmarketCapital1 .svg";
import { ReactComponent as Ceb } from "../../../../assets/icons/clean_energy_bridge.svg";
import { ReactComponent as TaraClimate } from "../../../../assets/icons/taraClimate.svg";
import { ReactComponent as Anthro } from "../../../../assets/icons/anthropoceneFixed .svg";
import { ReactComponent as ASix } from "../../../../assets/icons/articleSix.svg";
import { ReactComponent as Harvard } from "../../../../assets/icons/harvard.svg";
import { ReactComponent as CarbonPlace } from "../../../../assets/icons/carbonPlace.svg";
import Growald from "../../../../assets/images/growald.png";
import Card from "../../../../Components/Card/Card";
import Button from "../../../../Components/Button/Button";

let hfcArr = [
  "Global Financial Institutions (Equity, Debt, Philanthropic funds)",
  "Clean Energy Source Technology Experts",
  "CFPP Owners / RE Developers",
  "Legal Institutions (PPA amendments)",
  "Carbon Registry / Verifiers",
  "Policy Makers",
  "Methodology Developers",
  "Project Proponent",
  "Power Purchasers",
];

const registrationUrl =
  "https://events.teams.microsoft.com/event/f938eeb2-36ae-4f29-b665-c1548ee9a8f2@a8606430-fd02-4b66-b04e-e614589bb5ae/registration";

const Clem = ({
  eventsRef,
  isMobile,
  setMainEventActive,
}: {
  eventsRef: React.RefObject<HTMLDivElement>;
  isMobile: any;
  setMainEventActive: Function;
}) => {
  const cardContent = (
    title: string,
    date: string,
    address: string,
    registerBtn: boolean
  ) => (
    <div className={classes["card-content"]}>
      <div className={classes["card-title"]}>
        <p>{title}</p>
        {registerBtn && (
          <Button
            text="Register Now!"
            btnClickHandler={() =>
              window.open(registrationUrl, "_blank", "noopener,noreferrer")
            }
            className={classes["register-btn"]}
          />
        )}
      </div>
      <div className={classes["separator"]}></div>
      <div className={classes["card-info"]}>
        <p className={classes["card-date"]}>{date}</p>
        <p className={classes["card-address"]}>{address}</p>
      </div>
    </div>
  );

  const handleCardClick = (fromMainCard: boolean) => {
    if (eventsRef && eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: "smooth" });

      if (fromMainCard) {
        setMainEventActive(true);
      } else {
        setMainEventActive(false);
      }
    }
  };

  return (
    <div className={classes["clem-section-container"]}>
      <div className={classes["cs-info"]}>
        <p className={classes["cs-title"]}>CLEM (Clean Energy Mechanism)</p>
        <p className={classes["cs-desc"]}>
          Making Clean Energy Transitions Profitable
        </p>
      </div>
      <div className={classes["event-session-container"]}>
        <Card
          onClick={() => handleCardClick(true)}
          customClass={classes["main-event-container"]}
        >
          {cardContent(
            "Main Event",
            "26th Sept’24",
            "One World Trade Center 285 Fulton Street, 64th Floor New York, NY 10007",
            true
          )}
        </Card>

        <Card
          onClick={() => handleCardClick(false)}
          customClass={classes["breakout-session-container"]}
        >
          {cardContent(
            "Breakout Sessions",
            "23rd- 24th Sept’24",
            "One World Trade Center, Suite 8500, NY 10048",
            false
          )}
        </Card>
      </div>

      <div className={classes["hear-from-container"]}>
        <div className={classes["hfc-white-layer"]}>
          <p className={classes["hfc-title"]}>You will hear from</p>
          <div className={classes["separator"]}></div>
          <div className={classes["hfc-content"]}>
            <div className={classes["hfc-container-1"]}>
              <p>
                Global Financial Institutions (Equity, Debt, Philanthropic
                funds)
              </p>
              <p>Clean Energy Source Technology Experts</p>
              <p>CFPP Owners / RE Developers</p>
            </div>
            <div className={classes["hfc-container-2"]}>
              <p>Legal Institutions (PPA amendments)</p>
              <p>Carbon Registry / Verifiers</p>
              <p>Policy Makers</p>
            </div>
            <div className={classes["hfc-container-3"]}>
              <p>Methodology Developers</p>
              <p>Project Proponent</p>
              <p>Power Purchasers</p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes["speakers-from-container"]}>
        <div className={classes["sfc-white-layer"]}>
          <p className={classes["sfc-title"]}>Speakers from</p>
          <div className={classes["separator"]}></div>
          <div className={classes["sfc-content"]}>
            <Gfanz className={classes.gfanz} />
            <Smbc />
            <Aci />
            <Gpc />
            <Cix />
            <Idc className={classes.idc} />
            {/* <JacobSolutions /> */}
            <Cfa className={classes.cfa} />
            <Nrel />
            <ClimateBonds />
            <Ceb />
            <Anthro />
            <ASix />
            <NewMarket />
            {!isMobile ? (
              <img src={Growald} width={"12.5%"} height={"80px"} />
            ) : (
              <img src={Growald} width={"12.5%"} height={"40px"} />
            )}
            <TaraClimate />
            <Harvard />
            <CarbonPlace />
          </div>
        </div>
      </div>
      {!isMobile ? (
        <>
          <div className={classes["top-wave-container"]}>
            <TopWave />
          </div>
          <div className={classes["bottom-wave-container"]}>
            <DownWave />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Clem;
