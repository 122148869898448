import { useState } from "react";
import classes from "./AboutUs.module.scss";
import clemStrategy from "../../../../assets/icons/clemStrategy.svg";

const keyOutcomesData = [
  {
    title: "Current state discovery",
    description:
      "Data-driven extraction of technical and financials of current state of CFPP.",
  },
  {
    title: "Transition planning",
    description:
      "Practical location/country-specific transition plan (phased approach) by collaborating with CFPP owners, power purchasers, financial institutions, and policymakers.",
  },
  {
    title: "Economic gap",
    description:
      "Practical data-driven mechanism to nail down exact dollars CFPP owners have to forego as part of the transition.",
  },
  {
    title: "Energy mix",
    description:
      "Model-driven approaches covering region/country-specific policies to identify the potential clean energy source technologies and the cost associated along with financial analysis. Build clean energy source technologies working with region/country specific partners.",
  },
  {
    title: "Transition finance",
    description:
      "Data-driven, practical approach to identify transition financing requirements mapping with clear timelines covering different business models. Constructing transition financing requirements by using innovative global financial instruments to make transition profitable and working with our equity, debt global financial partners to raise funds.",
  },
  {
    title: "Transition Credits",
    description:
      "As an end-to-end player, methodology developer, and project proponent, we serve as a one-stop shop for managing the entire lifecycle of transition credits from concept to cashout.",
  },
  {
    title: "Blockchain-driven Marketplace",
    description:
      "Source the right clean energy source technology by working with clean energy source developers to speed up the transition.",
  },
  {
    title: "Financial analysis",
    description:
      "Provide detailed financial analysis with innovative transition financing instruments and transition credit cash flows for both equity and debt players.",
  },
  {
    title: "Summary",
    description:
      "Demonstrate profitability with transitions by comparing different options for CFPP owners and financial institutions.",
  },
];

function AboutUs() {
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      {!showContent ? (
        <div
          className={classes["title-container"]}
          onClick={() => setShowContent(true)}
        >
          <div className={classes["toggle"]}>+</div>
          <div className={classes.title}>About CLEM</div>
        </div>
      ) : null}

      {showContent ? (
        <div className={classes["open-container"]}>
          <div
            className={classes["open-title-container"]}
            onClick={() => setShowContent(false)}
          >
            <div className={classes.title}>About CLEM</div>
            <div className={classes["minus"]} />
          </div>

          <div className={classes.info}>
            <div className={classes["sub-title"]}>
              Practical and Crafted Approach
            </div>
            <p className={`${classes.text} ${classes.text1}`}>
              Coal-fired power generation remains a significant contributor to
              global carbon emissions, particularly in Asia, where a
              concentration of young CFPPs exists, averaging less than 15 years
              old. To address this pressing issue, CLEM, a first-of-its-kind
              clean energy transition tailor-made solution, takes a practical
              data-driven approach working with CFPP asset owners, power
              purchasers and policymakers by connecting the dots to automate,
              simplify and scale the clean energy transitions.
            </p>

            <div className={classes["sub-title"]}>
              Vision: Making Energy Transitions Profitable
            </div>
            <p className={classes.text}>
              CLEM’s vision is to make energy transition not just practical but
              profitable for CFPP asset owners and financiers. Leveraging a
              cutting-edge, data-driven approach, CLEM’s tailor-made solutions
              provide the most effective and strategic clean energy transition
              planning while ensuring profitability with a make-it-happen
              approach.
            </p>
          </div>

          <div className={classes["strategy-img-container"]}>
            <img
              className={classes["strategy-img"]}
              src={clemStrategy}
              alt="CLEM strategy and solution"
            />
          </div>

          <div className={classes["outcomes-container"]}>
            <div className={classes["outcomes-title"]}>
              KEY OUTCOMES<span>:</span>
            </div>
            <ul className={classes["outcomes-list"]}>
              {keyOutcomesData.map((data, index) => (
                <li key={index}>
                  <span className={classes["outcomes-subtext"]}>
                    {data.title} -{" "}
                  </span>{" "}
                  <span className={classes["outcomes-desc"]}>
                    {data.description}
                  </span>
                </li>
              ))}

              <li style={{ listStyle: "none" }}>
                <span className={classes["outcomes-subtext"]}>
                  Given the importance of PPA amendments, we work closely with
                  stakeholders such as purchasers, policymakers, and legal
                  institutions, while leveraging inputs from CLEM modules, to
                  automate PPA amendments.
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AboutUs;
