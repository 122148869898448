import classes from "./AboutUs.module.scss";
import Careers from "./Careers/Careers";

import headerImg from "../../assets/icons/headerImg.jpg";
import seGradientBackground from "../../assets/icons/hello.png";
import ceoImg from "../../assets/icons/ceoImg.png";
import GetInTouch from "../../Components/GetInTouch/GetInTouch";
import { ReactComponent as TopWave } from "../../assets/icons/top_wave.svg";
import { ReactComponent as DownWave } from "../../assets/icons/down_wave.svg";
import { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Set the tab name
    document.title =
      "Net-Zero Transitions with Custom, Data-Driven SaaS Solutions";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Navigate your decarbonization journey with our tech-driven SaaS platforms. Simplify and automate net-zero transitions with expert insights and global standards."
      );
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Navigate your decarbonization journey with our tech-driven SaaS platforms. Simplify and automate net-zero transitions with expert insights and global standards.";
      document.head.appendChild(meta);
    }

    // Clean up (if necessary) when component unmounts
    return () => {
      document.title = "Default Title"; // Restore the default title
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img className={classes["header-img"]} src={headerImg} alt="About Us" />

        <div className={classes.title}>About Us</div>
      </div>

      <div className={classes.story}>
        <div className={classes.title}>Our Story</div>

        <p className={classes.text}>
          Sustainability Economics was founded with a vision to accelerate the
          global transition to Net-Zero emissions. Headquartered in Singapore
          and the US, with a major development centre in Bangalore, we provide
          comprehensive Net-Zero solutions for high-emitting sectors worldwide.
          Our global team of experts and strategic partnerships ensure alignment
          with global standards throughout the transition journey to simplify,
          automate, and scale our solutions.
        </p>
        <div className={classes["top-wave-container"]}>
          <TopWave />
        </div>
        <div className={classes["bottom-wave-container"]}>
          <DownWave />
        </div>
      </div>

      <div className={classes["ceo-details"]}>
        <img src={ceoImg} alt="ceo" className={classes.ceoImg} />

        <div className={classes.info}>
          <div className={classes.quote}>”</div>
          <div className={classes["main-text"]}>
            Making the Net-Zero transition <span>effortless</span> <br />
            for our customers by connecting <br />
            <span>every step</span> along the journey.
          </div>

          <p className={classes["ceo-name"]}>
            Kasu Venkata Reddy, Co-Founder & CEO
          </p>
        </div>
      </div>

      <div className={classes["mission-vision-container"]}>
        <div className={classes.card}>
          <div className={classes.title}>Mission</div>

          <p className={classes.text}>
            Our mission is to drive the global shift towards a low-carbon future
            by empowering carbon-intensive sectors to transition to sustainable
            energy practices. We aim to deliver innovative, comprehensive
            solutions that reduce emissions and enhance operational efficiency.
          </p>
        </div>

        <div className={classes.card}>
          <div className={classes.title}>Vision</div>

          <p className={classes.text}>
            Sustainability Economics leads comprehensive Net-Zero transitions in
            high-emitting sectors, using advanced technology to streamline and
            scale solutions. Our global team of experts and strategic
            partnerships ensure alignment with global standards throughout the
            transition journey to simplify, automate, and scale our solutions.
          </p>
        </div>
      </div>

      <Careers />

      <GetInTouch
        imgSrc="https://se-website-image.s3.ap-south-1.amazonaws.com/wind-farm.jpg"
        content={
          <>
            Ready to <span>start</span> your <span>journey</span> towards a
            <span> sustainable, clean energy</span> future?
          </>
        }
      />
    </div>
  );
}

export default AboutUs;
