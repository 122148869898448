import classes from "./SectorWiseSolutions.module.scss";
import { ReactComponent as ArrowWithTail } from "../../../assets/icons/arrow_with_tail.svg";
import { sectorWiseSolutionsData } from "../../utils/data/sectorWiseSolutions";

const SectorWiseSolutions = () =>{


    return (
      <div className={classes.solutions}>
        <div className={classes.title}>Sector wise Solutions</div>

        <div className={classes.divider} />

        <div className={classes["sectors-container"]}>
          {sectorWiseSolutionsData.map((solution, index) => (
            <div className={classes.sector} key={index}>
              <img
                src={solution.imgSrc}
                alt={solution.title}
                className={classes.img}
              />
              <div className={classes.title}>{solution.title}</div>

              {solution.redirectLink ? (
                <ArrowWithTail className={classes.arrow} />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
}
export default SectorWiseSolutions;