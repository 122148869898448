import classes from "./BreakoutSession.module.scss";
import Button from "../../../../../Components/Button/Button";
import { useState, useCallback } from "react";
import { ReactComponent as Smbc } from "../../../../../assets/icons/Smbc.svg";
import { ReactComponent as Gfanz } from "../../../../../assets/icons/GFANZ.svg";
import { ReactComponent as SeLogo } from "../../../../../assets/icons/se_logo_with_text.svg";
import { ReactComponent as Yasui } from "../../../../../assets/icons/people/Yasui.svg";
import { ReactComponent as Habeck } from "../../../../../assets/icons/people/Habeck.svg";
import { ReactComponent as Cedric } from "../../../../../assets/icons/people/Cedric.svg";
import { ReactComponent as Kvr } from "../../../../../assets/icons/people/Kvr.svg";

let linkedInLinkMap = {
  "Arun Mahesh Babu":
    "https://www.linkedin.com/in/arun-mahesh-babu-m-s-ias-97a4b922/",
  "Kasu Venkata Reddy":
    "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
  "Paul Jacobson": "https://www.linkedin.com/in/paul-jacobson-b3716b13/",
  "Sarah Kurtz": "https://www.linkedin.com/in/sarah-kurtz-1167911/",
  "Dolph Habeck": "https://www.linkedin.com/in/dolph-habeck-8bb0464/",
  "Yuki Yasui": "https://www.linkedin.com/in/yuki-yasui-b029a71b/",
  "Dr. Ana Diaz":
    "https://www.linkedin.com/in/dr-eng-ana-diaz-vazquez-1b71321a6/",
  "Andrew Hohns": "https://www.linkedin.com/in/andrew-hohns-6901226/",
  "Cedric Rimaud": "https://www.linkedin.com/in/cedric-rimaud-6695936/",
  "Ulf Erlandsson": "https://www.linkedin.com/in/ulf-erlandsson/",
  "Ignatius Denny Wicaksono":
    "https://www.linkedin.com/in/ignatius-denny-wicaksono-cfa-frm-cipm-3622b236/",
  "John Lo": "https://www.linkedin.com/in/john-lo-a2738225a/",
  "Mikkel Larsen": "https://www.linkedin.com/in/mikkellarsen1/",
  "Chan-yau Chong": "https://www.linkedin.com/in/chan-yau-chong-44b03916/",
  "Andrea Guerrero-Garcia":
    "https://www.linkedin.com/in/andrea-guerrero-09073721/",
  "Elizabeth Willmott":
    "https://www.linkedin.com/in/elizabeth-willmott-26b9734/",
  "Ely Sandler": "https://www.linkedin.com/in/ely-sandler/",
  "Jamie Choi": "https://www.linkedin.com/in/jamie-choi-0058728/",
  "Peter F. Green": "https://www.linkedin.com/in/peter-f-green-9609b7230/",
} as any;

const registrationUrl =
  "https://events.teams.microsoft.com/event/f938eeb2-36ae-4f29-b665-c1548ee9a8f2@a8606430-fd02-4b66-b04e-e614589bb5ae/registration";

const BreakoutSession = ({ isMobile }: { isMobile: any }) => {
  const [sessionActive, setSessionActive] = useState(false);

  const linkClickHandler = (link: string) => () => {
    window.open(link, "_blank");
  };

  const timelineInfo = useCallback(
    (title: string, desc: string, time: string) => {
      return (
        <div className={classes["timeline-info-container"]}>
          <div className={classes["title-time-container"]}>
            <p className={classes["timeline-title"]}>{title}</p>
            <p className={classes["timeline-time"]}>{time}</p>
          </div>
          <p className={classes["timeline-desc"]}>{desc}</p>
        </div>
      );
    },
    []
  );

  const peopleInfo = useCallback((peopleArr: any[]) => {
    return (
      <div className={classes["people-info-contaier"]}>
        <ul className={classes["pi-desc"]}>
          {peopleArr.map((el) => (
            <div className={classes["image-container"]}>
              <div className={classes["image-content"]}>{el.image}</div>
              <div className={classes["type-content"]}>{el.type}</div>
              <span
                className={classes["panelist-name"]}
                onClick={linkClickHandler(linkedInLinkMap[el.name])}
              >
                {el.name}{" "}
              </span>
              <div className={classes["description"]}>{el.designation}</div>
            </div>
          ))}
        </ul>
      </div>
    );
  }, []);

  const blueStrip = useCallback((title: string, time: string) => {
    return (
      <div className={classes["blue-strip-container"]}>
        <p className={classes["bs-title"]}>{title}</p>
        <p className={classes["bs-time"]}>{time}</p>
      </div>
    );
  }, []);

  const firstEventClickHandler = () => {
    setSessionActive(false);
  };

  const secondEventClickHandler = () => {
    setSessionActive(true);
  };

  return (
    <>
      <div className={classes["breakout-session-container"]}>
        <div className={classes["register-now-container"]}>
          <p className={classes["rnc-title"]}>
            Expert-led discussions on the crucial role of philanthropic funds
            and equity players in driving the transition from CFPPs to clean
            energy solutions.
          </p>
          <p className={classes["rnc-date"]}>
            23<sup>rd</sup> - 24<sup>th</sup> Sept’24
          </p>
          <p className={classes["rnc-format"]}>Format: Hybrid</p>
          <p className={classes["rnc-address"]}>
            One World Trade Center, Suite 8500, NY 10048
          </p>
        </div>
        <div className={classes["agenda-container"]}>
          <p className={classes["agenda-title"]}>
            Agenda
            <div className={classes["events-btn-container"]}>
              <Button
                text="23rd Sept’24"
                btnClickHandler={firstEventClickHandler}
                btnStyles={{
                  backgroundColor: `${sessionActive ? "#EAF3FF" : "black"}`,
                  fontWeight: 400,
                  color: `${sessionActive ? "black" : "#EAF3FF"}`,
                  boxShadow: `${
                    !sessionActive ? "" : "1px 4px 5.5px 0px #90909040"
                  }`,
                  letterSpacing: "0.5px",
                }}
                className={classes["events-button"]}
              />
              <Button
                text="24th Sept’24"
                btnClickHandler={secondEventClickHandler}
                btnStyles={{
                  backgroundColor: `${sessionActive ? "black" : "#EAF3FF"}`,
                  fontWeight: 400,
                  color: `${sessionActive ? "#EAF3FF" : "black"}`,
                  boxShadow: `${
                    sessionActive ? "" : "1px 4px 5.5px 0px #90909040"
                  }`,
                  letterSpacing: "0.5px",
                }}
                className={classes["events-button"]}
              />
            </div>
          </p>
          <div className={classes["agenda-separator"]}></div>
          {timelineInfo(
            "Registration",
            "Networking",
            sessionActive ? "8:30 - 9:00 AM" : "3:30 - 4:00 PM"
          )}
          <div className={classes["agenda-separator"]}></div>
          {timelineInfo(
            sessionActive
              ? "Equity players opportunities in transition financing for CFPPs to clean energy source transitions"
              : "Philanthropic funds support to accelerate CFPPs to clean energy sources transitions.",
            "",
            sessionActive ? "9:00 - 10:30 AM" : "4:00 - 5:30 PM"
          )}
          <div className={classes["speaker-name"]}>Speakers</div>
          <div className={classes["panelist-container"]}>
            {peopleInfo(
              sessionActive
                ? [
                    {
                      name: "Dolph Habeck",
                      designation:
                        "Head of Sustainable Solutions, Americas, SMBC Group",
                      image: !isMobile ? (
                        <Habeck />
                      ) : (
                        <Habeck width={60} height={60} />
                      ),
                    },
                    {
                      name: "Cedric Rimaud",
                      designation:
                        "Deputy General Manager - Sustainability Solutions, SMBC Group",
                      image: !isMobile ? (
                        <Cedric />
                      ) : (
                        <Cedric width={60} height={60} />
                      ),
                    },
                    {
                      name: "Kasu Venkata Reddy",
                      designation: "CEO & Co-founder, Sustainability Economics",
                      image: !isMobile ? (
                        <Kvr />
                      ) : (
                        <Kvr width={60} height={60} />
                      ),
                    },
                  ]
                : [
                    {
                      name: "Yuki Yasui",
                      designation:
                        "Managing Director, Asia Pacific Network Glasgow Financial Alliance for Net Zero (GFANZ)",
                      image: !isMobile ? (
                        <Yasui />
                      ) : (
                        <Yasui width={60} height={60} />
                      ),
                    },
                    {
                      name: "Dolph Habeck",
                      designation:
                        "Head of Sustainable Solutions, Americas, SMBC Group",
                      image: !isMobile ? (
                        <Habeck />
                      ) : (
                        <Habeck width={60} height={60} />
                      ),
                    },
                    {
                      name: "Cedric Rimaud",
                      designation:
                        "Deputy General Manager - Sustainability Solutions, SMBC Group",
                      image: !isMobile ? (
                        <Cedric />
                      ) : (
                        <Cedric width={60} height={60} />
                      ),
                    },
                    {
                      name: "Kasu Venkata Reddy",
                      designation: "CEO & Co-founder, Sustainability Economics",
                      image: !isMobile ? (
                        <Kvr />
                      ) : (
                        <Kvr width={60} height={60} />
                      ),
                    },
                  ]
            )}
          </div>
          <div className={classes["agenda-separator"]}></div>
          {blueStrip(
            "Networking Break & Refreshments",
            sessionActive ? "10:30 - 11:00 AM" : "5:30 - 06:00 PM"
          )}
          <div className={classes["agenda-separator"]}></div>
          {/* <div className={classes["register-btn-container"]}>
            <Button
              text="Request For Invitation"
              btnClickHandler={() => setInvitationForm(true)}
              btnStyles={{
                backgroundColor: "#288f90",
                color: "white",
                fontWeight: 400,
                letterSpacing: "0.5px",
              }}
              className={classes["register-container"]}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default BreakoutSession;
